











































import Vue from 'vue';
import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
import GetFinishedLearningPathUnitsRequestViewModel from '@/src/services/viewModel/request/LearnitUnits/GetFinishedLearningPathUnitsRequestViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    learningUnits: [] as any,
    filter: new GetFinishedLearningPathUnitsRequestViewModel(),
    pagination: {} as ResponsePagingInfo,
    isLoading: false,
    errorResponse: undefined as any,
    isUserVerified: false,
  }),
  computed: {
    isAdmin() {
      return this.$auth.isInRole('Admin', 'Hostadmin');
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$withoutWatchers(() => {
          this.pagination.currentPage = 1;
        });
        this.$debounce(() => {
          this.refreshList();
        }, 300, this)();
      },
    },
    pagination: {
      deep: true,
      handler() {
        this.$debounce(() => {
          this.$withoutWatchers(() => {
            this.filter['paging.CurrentPage'] = this.pagination.currentPage;
            this.filter['paging.PageSize'] = this.pagination.pageSize;
          });
          this.refreshList();
        }, 600, this)();
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    this.filter['paging.IncludeCount'] = true;
    this.filter['paging.NoPaging'] = false;
    this.isUserVerified = this.$service.api.users.getHasAccessToPrivateContent();
    this.isLoading = false;
  },
  methods: {
    async refreshList() {
      this.isLoading = true;
      try {
        const response = await this.$service.api.learnitUnits.getFinishedLearningPathUnits(this.filter);
        this.learningUnits = response.items;
      } catch (error: any) {
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
  },
});
